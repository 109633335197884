module.exports = {
    companies: '/api/companies',
    dictionary: '/api/dictionary',
    filterOptions: '/api/filter-options?',
    filters: '/api/filters',
    whoami: '/api/whoami',
    login: '/api/login',
    loginMS: '/api/auth/ms/signin',
    register: '/api/register',
    github: '/api/oauth/github',
    exportedItem: '/api/exported-item',
    changes: '/api/changes',
    setSite: '/api/set-site',
    logout: '/api/logout',
    oath: '/api/oauth',
    addressBook: {
        base: '/api/address-book',
        contact: '/api/address-book-contact',
        filterOptions: '/api/address-book/filter-options',
    },
    apport: {
        draft: '/api/apport/consignments/draft',
        order: 'api/apport/consignments/order',
        draftBase: '/api/apport/consignments/draft-base',
        adHoc: '/api/apport/consignments/adhoc-mode',
        print: 'api/apport/consignments/print',
        dutiable: '/api/apport/consignments/dutiable',
        dutiableProducts: '/api/apport/consignments/dutiable-products',
    },
    findBookingUrl : '/api/apport/consignments/find-booking-url',

    goodTypeDictionary: {
        base: '/api/good-type-dictionary',
        search: '/api/good-type-dictionary/search',
    },

    sites: {
        base: '/api/sites',
        allowed: '/api/sites/allowed',
    },

    organizations: {
        base: '/api/organizations',
        sites: '/api/organizations/sites',
        allowed: '/api/organizations/allowed',
        active: '/api/organizations/active',
    },

    password: {
        base: '/api/password',
        reset: '/api/password/reset',
        email: '/api/password/email',
    },

    email: {
        base: '/api/email',
        resend: '/api/email/resend',
        verify: '/api/email/verify',
    },

    admin: {
        base: '/api/admin',
        dashboard: '/api/dashboard',
    },

    documents: {
        base: '/api/documents',
        download: '/api/documents/download',
    },

    comment: '/api/comment',
    comments: '/api/comments',
    media: '/api/media',
    sftp: '/api/sftp-accounts',

    addServices: {
        list: '/api/add-services',
        modules: '/api/add-services/modules',
    },

    users: {
        list: '/api/users',
        allowed: '/api/users/allowed',
        filters: '/api/users/filters',
        detail: '/api/user',
        inviteAzure: (id) => `/api/users/${id}/ms/invite`,
    },

    filter: {
        base: '/api/me/filters',
    },

    settings: {
        site: '/api/settings/site',
        password: '/api/settings/password',
        profile: '/api/settings/profile',
        profileBooking: '/api/settings/profile/booking',
        avatar: (id) => { return `/api/users/${id}/avatar` },
    },

    purchaseService: {
        base: '/api/purchase-service',
        companies: '/api/purchase-service/companies',
        companiesAdressess: '/api/purchase-service/companies/addresses',
        managers: '/api/purchase-service/managers',
        orders: '/api/purchase-service/orders',
        ordersItems: '/api/purchase-service/orders/order-items',
        ordersItemsSplit: '/api/purchase-service/orders/order-items/slit-item',
    },

    exceptions: {
        base: '/api/exceptions',
        resolve: '/api/xceptions/resolve',
        claim: '/api/xceptions/investigate',
        forward: '/api/exceptions/forward',
        statistics: '/api/exceptions/statistics',
        resolveConsignments: '/api/exceptions/resolve/consignments',
        claimConsignments: '/api/exceptions/claim/consignments',
    },

    xceptions: {
        base: '/api/xceptions',
        export: '/api/xceptions/export',
    },

    emissions: {
        base: '/api/emissions',
        filterOptions: '/api/emissions/filter-options',
        shipment: '/api/emissions/shipment',
        full: '/api/emissions/gri/full',
        fullExport: '/api/emissions/gri/full/export',
        kpi: '/api/emissions/figures/kpi',
        upload: '/api/emissions/upload',
        importVerify: '/api/emissions/import/verify',
        importProcess: '/api/emissions/import/process',
        export: '/api/emissions/export',
    },

    carriers: {
        base: '/api/carriers',
        search: '/api/carriers/search',
    },

    carrier: {
        base: '/api/carrier',
        unmatched: '/api/carrier/detectors/unmatched',
        statuses:  '/api/carrier_statuses',
    },

    reports: {
        base: '/api/reports',
        consignments: '/api/reports/consignments',
        emissions: '/api/reports/emissions',
        footprint: '/api/reports/footprint',
        leadtime: '/api/reports/leadtime',
        purchaseOrders: '/api/reports/purchase/orders',
    },

    carrierServices: {
        base: '/api/carrier-services',
        search: '/api/carrier-services/search',
        all: '/api/carrier-services/all',
        limited: '/api/carrier-services/limited',
    },

    shipments: {
        base: '/api/shipments',
        templates: '/api/shipments/templates',
        shipmentQueries: '/api/shipment/queries',
        shipmentTemplates: '/api/shipment/templates',
    },

    purchase: {
        base: '/api/purchase',
        ordersGetById: '/api/purchase/orders/get-by-id',
        orders: '/api/purchase/orders',
        orderItemsExport: '/api/purchase/order-items/export',
        ordersExport: '/api/purchase/orders/export',
        suppliers: '/api/purchase/suppliers',
        suppliersExport: '/api/purchase/suppliers/export',
        stats: '/api/purchase/stats'
    },

    priceleadtime: {
        base: '/api/priceleadtime',
        validate: '/api/priceleadtime/validate',
        inquiry: '/api/priceleadtime/inquiry',
        queries: '/api/priceleadtime/queries',
        invoiseImport: '/api/priceleadtime/invoice/articles/import',
        templates: '/api/priceleadtime/templates',
    },

    consignments: {
        base: '/api/consignments',
        filteredStatistics: '/api/consignments/filtered-statistics',
        export: '/api/consignments/export',
        notify: '/api/consignments/notify',
        bookmarks: '/api/consignments/bookmarks',
        share: '/api/consignments/share/',
        cannedResponse: '/api/consignments/canned-response',
        documents: '/api/consignments/documents',
        snoozeClose: '/api/consignments/snooze/status/closed/batch',
        remindersClose: '/api/consignments/reminders/status/closed/batch',
        snooze: '/api/consignments/snooze/batch',
        reminders: '/api/consignments/reminders/batch',
        events: '/api/consignments/events/',
        searates: '/api/consignments/searates',
        bookingInstructions: '/api/consignments/booking/instructions',
        notSeen: (uuid) => `/api/consignments/${uuid}/not-seen`,
        booking: {
            templates: '/api/consignments/booking/templates',
        },
        freightDocuments: '/api/consignments/freight-documents',
    },

    consignmentImports: {
        rules: {
            sites: '/api/consignment-imports/rules/sites',
            recalculate: '/api/consignment-imports/rules/recalculate',
            directions: {
                base: '/api/consignment-imports/rules/directions',
                recalculate: '/api/consignment-imports/rules/directions/recalculate',
            },
            share: {
                base: '/api/consignment-imports/rules/share',
                recalculate: '/api/consignment-imports/rules/share/recalculate',
            },
        }
    },

    cannedResponses: {
        base: '/api/canned-responses',
        placeholders: '/api/canned-responses/placeholders',
        action: '/api/canned-responses/action',
    },

    proxio: {
        calculationSimple: '/api/proxio/calculation/simple',
        calculationMultiple: '/api/proxio/calculation/multiple',
        calculationSmart: '/api/proxio/calculation/smart',
        calculationShared: '/api/calculation/response'
    },
    
    powerBi: {
        base: '/api/powerbi/report',
        default: '/api/powerbi/report/default',
        notDefault: '/api/powerbi/report/not-default',
    },

    roles: {
        base: '/api/roles',
    },

    permissions: {
        tree: '/api/permissions/tree',
        routes: '/api/permissions/routes',
    },

    sealines: {
        base: '/api/searates/sealines',
    },

    notifications: {
        base: '/api/notifications',
        filterOptions: '/api/notifications/filter-options',
    },

    booking: {
        base: '/api/booking/unifaun-agreement-rules',
        id: id => `/api/booking/unifaun-agreement-rules/${id}`,
        batch: '/api/booking/unifaun-agreement-rules/batch',
    },

    customs: {
        declarations: '/api/customs/declarations',
        items: '/api/customs/items',
        files: '/api/customs/files',
    },
}
