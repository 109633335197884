<template>
    <li
        v-if="adminOption(item) && !item.hidden && hasAccess(item) && hasPermission(item)"
        class="pr-0 mb-1 sidebar-item"
        :class="{ 'toggled' : isToggled(index) }"
    >

        <router-link v-if="item.route && item.items && !item.items.length" :to="{ name: item.route, params: item.routeParams }" class="flex items-center h-10 ml-2 mr-2 sidebar-link align-center border-radius" :class="[activeClass(item), item.name]" @click.prevent="setPath(item.route)">
            <span class="w-10 sidebar-item__icon">
                <svg-importer :icon-name="item.icon" :key="item.icon" />
            </span>

            <span class="sidebar-item__text text-ellipsis pr-1">{{ item.title }}</span>

            <div v-show="item.loading" class="relative ml-auto">
                <spinner size="20px" />
            </div>
        </router-link>

        <a v-else-if="item.items" href="#" class="flex items-center h-10 ml-2 mr-2 sidebar-link align-center border-radius" :class="[activeClass(item), item.route || item.name]" @click.prevent="itemClicked(item, index)">
            <span class="w-10 sidebar-item__icon">
                <svg-importer :icon-name="item.icon" :key="item.icon" />
            </span>

            <span class="sidebar-item__text text-ellipsis pr-1">{{ item.title }}</span>

            <span v-show="item.items.length" class="ml-auto text-2xl mr-2" @click.prevent.stop="toggleMenu(index)">
                <svg-importer v-if="item.items" icon-name="icons/arrow" />
            </span>
        </a>

        <router-link
            v-else
            :to="{ name: item.route, params: item.routeParams }"
            class="flex items-center h-10 ml-2 mr-2 sidebar-link align-center border-radius"
            :class="[activeClass(item), item.route || item.name]"
            @click.prevent="setPath(item.route)"
        >
            <span class="w-10 sidebar-item__icon">
                <svg-importer :icon-name="item.icon" :key="item.icon" />
            </span>

            <span class="sidebar-item__text text-ellipsis pr-1">{{ item.title }}</span>

            <span class="ml-auto text-2xl" @click.prevent.stop="toggleMenu(index)">
                <fa v-if="item.items" :icon="['fal',isToggled(index) ? 'angle-down' : 'angle-right']" />
            </span>
        </router-link>

        <ul v-if="item.items" v-show="isToggled(index)" class="list-unstyled nav-item-sublist">
            <li
                v-if="hasAccess(child) && !child.ignore && hasAccessPriceLead(child)"
                v-for="child in getItems(item.items)"
                :key="child.id"
                class="flex items-center h-10 p-2 align-center border-radius pl-6 ml-4 mr-4 mt-1"
                :class="activeBackground(child)"
            >
                <router-link
                    v-if="child.route"
                    class="pl-16 font-light"
                    :to="{ name: child.route, params: { ...child.routeParams, ...pathParams(child.route) } }"
                    @click.native="setPath(child.route)"
                >
                    {{ child.title }}
                </router-link>
            </li>
        </ul>
    </li>
</template>
<script>
import hasEmergencyPltAccess from '@/utils/pltAccessor.js';
import Spinner from '~/components/Spinner';

export default {
  props: {
    item: Object,
    index: Number,
    indentSubs: String,
    isAdmin: Boolean,
    isMin: Boolean,
  },
  components: { Spinner },
  data: () => ({
  }),
  computed: {
    toggled () {
      return this.$store.getters['sidebar/toggled']
    },
    user() {
        return this.$store.getters["auth/user"];
    },
    sites() {
        return this.$store.getters["auth/sites"];
    },
    currentRouteName() {
        return this.$route.name;
    },
    isAdminLevel() {
        return Boolean(this.user.level === 'super')
    },
    applicationsSet() {
        return this.sites.reduce((accumSet, site) => {
            const siteValue = Array.isArray(site) ? site[0] : site;
            if (siteValue && siteValue.applications && siteValue.applications.length) {
                siteValue.applications.forEach(app => accumSet.add(app));
            }

            return accumSet;
        }, new Set());
    },
  },

  watch: {
    /*
     * Toggle items if one of them is active
     */
    $route(to, from){
      if(this.active(this.item) && !this.isToggled(this.index)){
        this.toggleMenu(this.index);
      }
    }
  },
  methods: {
    hasAccess(item) {
        if (item.title === 'Quote & Booking' && hasEmergencyPltAccess(this.user)) {
            return true;
        }

        if (typeof item.super === 'boolean' && item.super && this.user.level != 'super') {
            return false;
        }
        
        if (item.application) {
            return this.hasApplication(item.application);
        }

        if(item.access === undefined) {
            return true;
        }

        if(item.access === this.user.level || this.user.level === 'organization-admin' || this.user.level === 'super') {
            return true;
        }
    },

    hasPermission(item) {

        if (! item.permission) {
            return true;
        }

        return this.user.permissions[item.permission]
    },

    hasApplication(appKey){
        if (!this.sites || !this.sites.length) {
            return;
        }

        if (!this.sites || !this.applicationsSet.size){
            return false;
        }

        return this.applicationsSet.has(appKey)
    },

    hasAccessPriceLead(item) {
        if (item.title != 'Price & leadtime') {
            return true;
        }

        if (item.client && this.isAdminLevel) {

            return false;
        }

        return true;
    },

    adminOption(item) {
        if(item.admin === undefined) {
            return true;
        }

        if (item.client && this.isAdminLevel) { 
            return false;
        }

        if (item.client && !this.isAdminLevel) {
            return true;
        }

        return item.admin && (this.user.level === 'organization-admin') || (this.user.level === 'super') || (this.user.level === 'site-admin');
    },

    activeBackground(child) {
        const className = this.isAdmin ? 'bg-gray-900 text-black' : 'bg-gray-200 text-black';
        
        if(this.isAdmin || child.routeParams)   {
            return this.activeExact(child) ? className : '';
        }

        if(this.activeExact(child) || this.currentRouteName.includes(child.route))  {
            return 'bg-gray-200 text-black';
        }
    },
    pathParams(route) {
        if (route === 'settings.organization.edit') {
            return {id: this.user.organization.id};
        }

        return {}
    },

    activeClass(item) {
        if (this.isAdmin && this.currentRouteName.includes(this.item.route)) {
            return 'router-link-active';
        }

        if(this.isAdmin && this.active(item)) {
            return 'bg-blue-900';
        }

        if(this.active(item)) {
            return 'router-link-active';
        }
    },

    active (item) {
        const hasChildren = item.items;

        if (! hasChildren) {
            return item.route === this.currentRouteName
        }

        // If the item has a child that is the current route, it is active.
        return !!item.items.find((child) => {
            return child.route === this.currentRouteName;
        })
    },

    activeExact(item){
        let isValid = item.route === this.$route.name;
        const itemParamsKeys = item.routeParams ? Object.keys(item.routeParams) : [];

        if (itemParamsKeys.length) {
            // finds route & item params values mismatch
            return isValid && !itemParamsKeys.find(key => item.routeParams[key] != this.$route.params[key]);
        }

        return isValid;
    },

    itemClicked (item, index) {

        if (!item.items) {
            this.$router.push({ name: item.route })

            return;
        }

        // If the items has children
        if (this.isMin) {
            this.$emit('isMin', false);
            this.$store.commit('sidebar/OPEN', index)

            const accessableChildRoute = item.items.find(route => this.hasAccess(route));

            if (!accessableChildRoute) {
                return;
            }

            this.$router.push({ name: accessableChildRoute.route, params: accessableChildRoute.routeParams || {} }).catch(e => {})

            return;
        }

        this.toggleMenu(index)
    },


    setPath(route) {
        this.$emit('path', route);
    },

    getItems (items) {
      return items.filter(item => {
        return !item.hidden
      })
    },

    isToggled (id) {
      return this.toggled === id
    },

    toggleMenu (id) {
      this.$store.commit('sidebar/TOGGLE', id)
    }
  }
}
</script>
