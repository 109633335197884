import auth from '~/middleware/auth';
import guest from '~/middleware/guest';
import admin from '~/middleware/admin';
import siteAdmin from '~/middleware/site-admin';
import locale from '~/middleware/locale';
import checkAuth from '~/middleware/check-auth';
import organization from '~/middleware/organization'

function page (path) {
  return () => import(`~/pages/${path}`).then(m => m.default || m)
}

function addMiddleware (routes, middleware) {
  return routes.map(route => {
    if (!route.meta) {
      route.meta = {
        middleware: middleware
      }
    } else {
      route.meta.middleware = middleware
    }

    if (!route.meta.layout) {
      route.meta.layout = 'default';
    }

    return route
  })
}

const publicPaths = [
  { path: '/', name: 'blank', redirect: { name: 'home' } },
  { path: '/login', name: 'login', component: page('auth/login.vue'), meta: { layout: 'login' } },
  { path: '/password/reset', name: 'password.request', component: page('auth/password/email.vue'), meta: { layout: 'login' } },
  { path: '/password/reset/:token', name: 'password.reset', component: page('auth/password/reset.vue'), meta: { layout: 'login' } },
  { path: '/password/welcome/:token', name: 'password.reset', component: page('auth/password/welcome.vue'), meta: { layout: 'login' } },
  { path: '/email/verify/:id', name: 'verification.verify', component: page('auth/verification/verify.vue'), meta: { layout: 'login' } },
  { path: '/email/resend', name: 'verification.resend', component: page('auth/verification/resend.vue'), meta: { layout: 'login' } },
]

const freePaths = [
  { path: '/shared/consignments/:uuid', name: 'shared.consignments.detail', component: page('transport/Detail.vue'), meta: { layout: 'empty' } },
  { path: '/shared/price-leadtime/:id', name: 'shared.priceleadtime.detail', component: page('transport/price-leadtime/shared-result/index.vue'), meta: { layout: 'empty' } },
];

const basePaths = [
  { path: '/home', name: 'home', component: page('home.vue') },

  { path: '/faq', name: 'faq', component: page('faq.vue') },

  { path: '/analytics', name: 'analytics', component: page('reports/Report.vue') },
  { path: '/reports/:id', name: 'reports.single', component: page('reports/Index.vue') },
  { path: '/reports', name: 'reports.empty', component: page('reports/Empty.vue') },

  { path: '/transport', name: 'transport', component: page('transport/index.vue') },
  { path: '/transport/shipments', name: 'transport.shipments', component: page('transport/Shipments.vue') },
  { path: '/transport/shipments/:uuid', name: 'transport.shipments.detail', component: page('transport/Detail.vue') },
  { path: '/transport/emissions', name: 'transport.emissions', component: page('transport/emissions/EmissionsIndex.vue') },
  { path: '/transport/bookings', name: 'transport.bookings', component: page('transport/bookings/Index.vue') },

  { path: '/settings', redirect: { name: 'settings.profile' } },
  { path: '/settings/profile', name: 'settings.profile', component: page('settings/profile.vue') },
  { path: '/settings/password', name: 'settings.password', component: page('settings/password.vue') },

  { path: '*', component: page('errors/404.vue') },

  { path: '/shipments/example', name: 'shipments.example', component: page('transport/DetailExample.vue'), meta: { layout: 'empty' } },

  { path: '/customs-declarations', name: 'customs-declarations', component: page('declarations/Index.vue') },
  // { path: '/customs-declarations/:id', name: 'customs-declarations.id', component: page('declarations/_id.vue') },

  { path: '/notifications', name: 'notifications', component: page('notifications/Index.vue') },
  { path: '/notifications/canned-responses', name: 'notifications.cannedresponses', component: page('notifications/canned-responses/index.vue') },
  
  { path: '/transport/price-leadtime', name: 'transport.priceleadtime', component: page('transport/PriceLeadTime.vue') },
  { path: '/transport/price-leadtime/template/:id?', name: 'transport.priceleadtime.template', component: page('transport/PriceLeadTimeFormTemplate.vue') },
  { path: '/transport/price-leadtime/form', name: 'transport.priceleadtime.form', component: page('transport/PriceLeadtimeForm.vue') },
  { path: '/transport/price-leadtime/templates', name: 'transport.priceleadtime.templates', component: page('transport/Templates.vue') },
  { path: '/transport/transport-booking', name: 'transport.transport-booking', component: page('transport/PriceLeadTime.vue') },
  { path: '/transport/transport-booking/form', name: 'transport.transport-booking.form', component: page('transport/PriceLeadtimeForm.vue') },
  { path: '/transport/transport-booking/template/:id?', name: 'transport.transport-booking.template', component: page('transport/PriceLeadTimeFormTemplate.vue') },
  { path: '/transport/transport-booking/templates', name: 'transport.transport-booking.templates', component: page('transport/Templates.vue') },
  { path: '/transport/transport-booking/ad-hoc', name: 'transport.transport-booking.ad-hoc', component: page('transport/PriceLeadtimeForm.vue') },
  { path: '/transport/priceleadtime', name: 'transport.priceleadtime.standalone', component: page('transport/PriceLeadtimeForm.vue') },
  
  { path: '/customs', name: 'customs', component: page('customs/Index.vue') },
  { path: '/customs/:id', name: 'customs.card', component: page('customs/Card.vue') },
]

const sitePath = [
    { path: '/settings/site/edit/:id', name: 'settings.sites.edit', component: page('admin/SiteCreate.vue') },
    { path: '/settings/sites', name: 'settings.sites', component: page('settings/Sites.vue') },
]

const organizationPaths = [
    { path: '/settings/organization/edit/:id', name: 'settings.organization.edit', component: page('admin/OrganizationCreate.vue') },
    { path: '/settings/user/edit/:id', name: 'settings.user.edit', component: page('admin/UserCreate.vue') },
    { path: '/settings/sites/applications', name: 'settings.applications', component: page('settings/Applications.vue') },
    { path: '/settings/site/:id/applications', name: 'settings.site.applications', component: page('admin/Applications.vue') },
]

const adminPaths = [
    { path: '/admin/sites', name: 'admin.sites', component: page('admin/SiteIndex.vue') },
    { path: '/admin/site/edit/:id', name: 'admin.sites.edit', component: page('admin/SiteCreate.vue') },
    { path: '/admin/site/create', name: 'admin.sites.create', component: page('admin/SiteCreate.vue') },

    { path: '/admin/organizations', name: 'admin.organizations', component: page('admin/OrganizationIndex.vue') },
    { path: '/admin/organization/edit/:id', name: 'admin.organizations.edit', component: page('admin/OrganizationCreate.vue') },
    { path: '/admin/organization/create', name: 'admin.organizations.create', component: page('admin/OrganizationCreate.vue') },

    { path: '/admin/sftp-accounts', name: 'sftp-accounts.index', component: page('admin/SftpAccountIndex.vue') },

    { path: '/admin/users', name: 'admin.users', component: page('admin/UserIndex.vue') },
    { path: '/admin/users/edit/:id', name: 'admin.users.edit', component: page('admin/UserCreate.vue') },
    { path: '/admin/user/create', name: 'admin.users.create', component: page('admin/UserCreate.vue') },

    { path: '/admin/translate', name: 'translate', component: page('admin/translate/TranslateIndex.vue') },
    { path: '/admin/agents', name: 'agents', component: page('admin/agent/AgentIndex.vue') },

    { path: '/admin/carriers', name: 'admin.carriers', component: page('admin/carrier/CarrierIndex.vue') },
    { path: '/admin/carriers/create', name: 'admin.carriers.create', component: page('admin/carrier/CarrierEdit.vue') },
    { path: '/admin/carriers/:id', name: 'admin.carriers.edit', component: page('admin/carrier/CarrierEdit.vue') },

    { path: '/admin/shipments', name: 'admin.shipments', component: page('transport/ShipmentsAdmin.vue')},
    { path: '/admin/shipments/:uuid', name: 'admin.shipments.detail', component: page('transport/Detail.vue') },

    { path: '/admin/statuses-dictionary', name: 'admin.statuses_dictionary', component: page('admin/statuses-dictionary/Index.vue') },

    { path: '/admin/good-type-dictionary', name: 'admin.good_type_dictionary', component: page('admin/good-type-dictionary/Index.vue') },

    { path: '/settings/site/:id/exceptions', name: 'settings.site.exceptions', component: page('settings/ExceptionSettings.vue') },

    { path: '/admin/dashboard', name: 'admin.dashboard', component: page('admin/dashboard/Index.vue') },

    { path: '/admin/redirect/price-leadtime', name: 'admin.redirect-price-leadtime', component: page('admin/redirect/PriceLeadtime.vue') },
    { path: '/admin/notifications', name: 'admin.notifications', component: page('admin/notifications/Index.vue') },
    { path: '/admin/notifications/canned-responses', name: 'admin.notifications.cannedresponses', component: page('admin/notifications/CannedResponsesIndex.vue') },

    { path: '/admin/roles', name: 'admin.roles', component: page('admin/roles/Index.vue') },
    { path: '/admin/booking/agreements', name: 'admin.booking.agreements', component: page('admin/booking/Agreements.vue') },
]

export default [
  ...addMiddleware(freePaths, [locale, checkAuth]),
  ...addMiddleware(publicPaths, [locale, checkAuth, guest]),
  ...addMiddleware(basePaths, [locale, checkAuth, auth]),
  ...addMiddleware(sitePath, [locale, checkAuth, auth, siteAdmin]),
  ...addMiddleware(organizationPaths, [locale, checkAuth, auth, organization]),
  ...addMiddleware(adminPaths, [locale, checkAuth, auth, admin])
]